import * as React from "react";
import Layout from "../../components/Layout";
import GeneralTab from "../../components/HTabs";
import { Seo } from "../../components/seo";
import Counter from "../../components/Counter";
import FAQAccord from "../../components/GFlush";
import Service from "../../components/Services";
// import Hero from "../../components/Hero";
import About from "../../components/About";
import ImgContent from "../../components/GSTTemp/ImgContent";
import Package from "../../components/GSTTemp/Package";

export default function NidhiCompCompliance() {
  // Banner slider
  // const NidhiCompSlider = [
  //   {
  //     header_color: false,
  //     content_color: false,
  //     marginTop: 30,
  //     background: `linear-gradient(139.4deg, rgb(198, 243, 242) 28.54%, rgb(181, 201, 253) 104.43%)`,
  //     header: `Online Nidhi Company Compliance Registration`,
  //     content: `TODAYFILINGS, India's largest Nidhi Company Compliance service in
  //     india, can assist you with your business.`,
  //     image: "/imgs/business/sliders/pvt-slide.png",
  //     alt_tag:
  //       "Best Nidhi Company Compliance Registration Service Near Bangalore",
  //   },
  //   {
  //     header_color: false,
  //     content_color: false,
  //     marginTop: 60,
  //     background: `linear-gradient(139.4deg, rgb(207, 249, 216) 28.54%, rgb(243, 238, 160) 104.43%)`,
  //     header: `Nidhi Company Compliance Registration in Hosur`,
  //     content: `Best Nidhi Company Compliance Registration Service in Hosur`,
  //     image: "/imgs/registration/ipr/td8.png",
  //     alt_tag: "Best Nidhi Company Compliance Registration in Hosur",
  //   },
  //   {
  //     background: `linear-gradient(139.4deg, rgb(252, 237, 216) 28.54%, rgb(245, 197, 198) 104.43%)`,
  //     marginTop: 20,
  //     header: `Best Nidhi Company Compliance Registration`,
  //     content: `Top most Success Rated Nidhi Company Compliance Registration Service`,
  //     image: "/imgs/registration/iec/iec-reg.png",
  //     alt_tag: "Nidhi Company Compliance Registration Service in Hosur",
  //   },
  // ];

  /* Package Data */
  const PackageCompData = {
    header: `Nidhi Company Compliance`,
    buyBtnLink: `#pricing-buy`,
    price: `44999`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: "/imgs/icons/gst.png",
        alt_tag: "PAN & TAN Registration",
        background: "",
        plus: "+",
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: "/imgs/icons/itr_filing.png",
        alt_tag: "TODAYFILINGS ITR E-filing service",
        background: "bg-f78acb",
        plus: "+",
        plus_dnone: "d-none d-md-block",
      },
      {
        points: `MSME Registration`,
        icon: "/imgs/icons/enterprises.png",
        alt_tag: "MSME Registration",
        background: "bg-eb6b3d",
        plus: "+",
      },
      {
        points: `Tax Consultancy Service `,
        icon: "/imgs/icons/consultant.png",
        alt_tag: "Tax Consultancy Service",
        background: "bg-c679e3",
      },
    ],
  };
  /* About Data Without Form*/
  const NidhiCompAboutData = {
    title: `Nidhi Company Compliance`,
    sub_title: `Best Nidhi Company Compliance Filing services in India in just 7 Days`,
    paragraph: `Nidhi Company is a kind of NBFC, or Non-Banking Financial
    Company. Nidhi Company must submit a few annual compliance
    documents, which are referred to as Nidhi Company
    Compliances, just like any other company. The Nidhi Rules
    2014 and the Companies Act 2013 outline the legal
    requirements that apply to Nidhi Company.`,
    header: 'Statutory Nidhi Company Compliance ',
    points: [
      `Income Tax Returns`,
      `Financial Statement Returns`,
      `Establish Compulsory Meetings`,
      `Preservation of the Compulsory register`,
    ],
  };
  /* Service Data */
  const NidhiCompData = {
    header: `Advantages of Nidhi Company Compliance`,
    content: [],
    image: '/imgs/gifs/img5.gif',
    alt_img: 'TODAYFILINGS-nidhi-company-compliance',
    points: [
      `Simple to Create a Company`,
      `Affordable Registration`,
      `High Level of Confidence`,
      `No RBI rules exist`,
      `Very Little Risk`,
      `No outside interference`,
    ],
  };

  /* FaQ Data */
  var NidhiCompFAQ = [
    {
      header: 'What is Nidhi Company?',
      body: [
        {
          content: ` Nidhi Company is a kind of NBFC, or Non-Banking Financial Company.
          Like any other business, Nidhi Company is required to submit annual
          compliance documents, popularly referred to as Nidhi Company
          Compliances.`,
          icon: false,
        },
      ],
    },
    // 2
    {
      header: 'What advantages do Nidhi Company compliances offer?',
      body: [
        {
          content: `Simple to Create a Company.`,
          icon: true,
        },
        {
          content: `Affordable Registration.`,
          icon: true,
        },
        {
          content: `Nidhi Company Has a High Level of Confidence.`,
          icon: true,
        },
        {
          content: `No RBI rules exist.`,
          icon: true,
        },
        {
          content: `Very Little Risk.`,
          icon: true,
        },
      ],
    },
    {
      header: `Who is eligible to join or partner with a Nidhi Company?`,
      body: [
        {
          content: ` Anyone who meets the standard age verification requirement of 18
          years old or older is eligible to join the Nidhi Companies. The
          person who is hoping to join needs valid their ID and address
          proof.`,
          icon: false,
        },
      ],
    },
    // 3
    {
      header: ' What should be the goal of Nidhi Company?',
      body: [
        {
          content: `Nidhi accepts deposits from its members of the company and lends
          money with the intention of encouraging members to be thrifty and
          save money. Nidhi Company's major goal is to continue the practice
          of taking deposits from and lending money to its members.`,
          icon: false,
        },
      ],
    },
    // 4
    {
      header: 'How is the fund used by the Nidhi Company?',
      body: [
        {
          content: `According to Nidhi Rules, the Nidhi Company uses the assets to
          make loans to investors. It provides small company and funding
          loans in the form of cash.`,
          icon: false,
        },
      ],
    },
    {
      header: ' What should be the objective of Nidhi Company? ',
      body: [
        {
          content: `Nidhi with the object of developing the propensity for thrift and reserve funds among its individuals,
           accepting deposits from, and lending to, its individuals.`,
          icon: true,
        },
        {
          content: `The essential object of Nidhi is to carry on the matter of accepting deposits and loaning cash to members.`,
          icon: true,
        },
      ],
    },
    {
      header: ' What are the requirements of Nidhi Company compliances? ',
      body: [
        {
          content: `Compliances help in forming exact insights about the company’s working performance.`,
          icon: true,
        },
        {
          content: `It is necessary for every company which is registered under the Companies Act 2013 to file for 
          the compliances.`,
          icon: true,
        },
        {
          content: `Moreover Nidhi Company falls under the category of public company hence in order to
           protect the interest of its stakeholders it becomes compulsory for the Nidhi Company to follow the compliances.`,
          icon: true,
        },
      ],
    },
    {
      header: ' What provisions are applicable to Nidhi Company? ',
      body: [
        {
          content: `As they are joined into the idea of Public Company so rules and guidelines of Companies 
          Act, 2013 are pertinent.`,
          icon: true,
        },
        {
          content: `RBI arrangements identified with Interest rate payable on store are relevant to Nidhi Companies. 
          In any case, the center arrangements of RBI are not material to Nidhi Companies as RBI has absolved the
           Nidhi Companies from the equivalent.`,
          icon: true,
        },
      ],
    },
    {
      header: ' Who can become member of a Nidhi Company? ',
      body: [
        {
          content: `Any individual who is over 18 years old according to the standard age verification can 
          turn into an individual from the Nidhi Companies. The individual covetous of turning into a part ought
           to have legitimate ID Proof and Address Proof.`,
          icon: false,
        },
      ],
    },
    {
      header: ' What are the requirements for a Nidhi Company registration? ',
      body: [
        {
          content: `To incorporate a Nidhi Company it is important to satisfy following rules:`,
          icon: false,
        },
        {
          content: `It ought to have in any event atleast 3 Directors.`,
          icon: true,
        },
        {
          content: `It ought to have in any event atleast 7 Members.`,
          icon: true,
        },
        {
          content: `The principle goal to be written in the MOA ought to be to develop the propensity for 
          frugality and reserve funds among its individuals. Also, it can acknowledge stores and loan cash 
          just to its individuals and will work for the shared advantage of its individuals.`,
          icon: true,
        },
      ],
    },
  ];

  /* Htabs */
  const HTabsData = {
    id: 'features',
    heading: 'Benefits of Nidhi Company Compliance',
    paragraph: `TThe accounting department is one of the most important parts of
    a company's organizational structure. our consulting services
    are`,
    body: [
      {
        tab_title: 'Pre-Incorporation',
        tab_content: [
          {
            content_title: "Nidhi Company's Pre-Incorporation Compliances",
            content_paragraph: 'Essential compliances to be followed are:',
            points: [
              `Nidhi Company must have a minimum of 7 members, and
              three of those members must be nominated as Directors.`,
              `The minimum capital required to form a Nidhi Company is
              Rs 5, 000.`,
              `Loan's interest rate will not be greater than 7.5% over
                the most noteworthy rate of interest offered on
                deposits.`,
              `Nidhi Company does not allow corporate entities or
                trusts to be partners.`,
              ` The identical provisions of the offering must be
                followed if the preference shares are dispersed or
                issued.`,
              `Unable to identify the location of more than 20% of Net
                Owned Funds. A minor cannot be considered as a partner
                of the Nidhi Company.`,
              `Nidhi Limited must be positioned after the company name.`,
              `   If Nidhi Company does not make a profit after an
                inspection for three years in a row, it is not permitted
                to open any branches.`,
            ],
          },
        ],
        content_img: '/imgs/assited/pre-incorpration.png',
        alt_img: 'TODAYFILINGS-Pre-Incorporation Compliances',
      },
      {
        tab_title: 'Post-Incorporation',
        tab_content: [
          {
            content_title: "Nidhi Company's Post-Incorporation Compliances",
            content_paragraph: 'Some post-Incorporation compliance are,',
            points: [
              `Within a year of its incorporation, the number of members
                cannot be fewer than 200.`,
              `The stores must be at least 10% of the unpaid deposits,
                according to Rule 14 of the Nidhi Rules, 2014.ce.`,
              `The Net Owned Fund must be at least Rs. 10 lakh.`,
              `Support for financial statements or accounting records.`,
              `Net-owned Funds to Stores should not be more than 1:20 in
                ratio.`,
              `Keep the legal Registers updated and Gather meetings
                required by law.`,
            ],
          },
          {
            content_title: 'Penalties',
            content_paragraph: '',
            points: [
              `If a corporation doesn't provide the required paperwork on
                time, the Nidhi Bank Operators may take legal action
                against them.`,
              `A penalty
                of up to Rs 5000 may be imposed on the responsible
                personnel if the Company fails to comply with the
                compliance requirements.`,
              `If the infraction continues, a subsequent fine of Rs 500
                will be applied consistently.`,
              `As a result, it is crucial to use industry leading
                specialists' compliance upkeep administrations.`,
            ],
          },
        ],
        content_img: '/imgs/assited/post-incorpration.png',
        alt_img: 'Nidhi Compliances penalties',
      },
      {
        tab_title: 'NDH',
        tab_content: [
          {
            content_title: 'NDH 1',
            content_paragraph: `Form NDH-1 must be properly certified by an authorized
              chartered accountant and submitted with the required fees
              by Nidhi Companies. Once your registration is complete,
              the business must submit the annual return within three
              months or 90 days that start at the end of the first or
              second year.`,
            points: [],
          },
          {
            content_title: 'NDH 2',
            content_paragraph: `If any of the following compliances are not met, the form
              NDH-2 must be filed in order to request a time extension:`,
            points: [
              `If the business doesn't sign up at least 200 new partners
              in a year`,
              `If the business is unable to keep its net owned funds in a
              deposit ratio of 1:10, an NDH-2 form must be submitted to
              the regional director along with payment of the applicable
              fee, and the director may then issue orders within a month
              or 30 days of receiving the application.`,
            ],
          },
          {
            content_title: 'NDH 3',
            content_paragraph: `The Nidhi Company also submits a crucial annual return
              form, which is done biannually.`,
            points: [],
          },
          {
            content_title: 'Registers of Accounts',
            content_paragraph: `Every Nidhi business must guarantee that its books of
              accounts are kept accurately.`,
            points: [],
          },
        ],
        content_img: '/imgs/assited/ndh.png',
        alt_img: 'TODAYFILINGS-Nidhi Company Compliances',
      },
      {
        tab_title: 'Statutory Compliance',
        tab_content: [
          {
            content_title: 'Establish Compulsory Meetings',
            content_paragraph: `Conducting a board meeting with the company's directors
              and shareholders the creation of financial statements or
              reports It is required for a Nidhi corporation to prepare
              financial reports and statements, which include the
              balance sheet, cash flow statement, and profit and loss
              account statement.`,
            points: [],
          },
          {
            content_title: 'Preservation of the Compulsory register',
            content_paragraph: `The statutory registers must be maintained by Nidhi
              Company in accordance with the Companies Act of 2013. The
              filing of annual returns is one of the requirements for a
              Nidhi Company.`,
            points: [],
          },
          {
            content_title: 'Income Tax Returns',
            content_paragraph: `The annual income tax returns for Nidhi Company must be
              filed no later than September 30th of the following fiscal
              year.`,
            points: [],
          },
          {
            content_title: 'Filing Financial Statement Returns (AOC-4)',
            content_paragraph: `The Nidhi Company's budget statement or reports are
              detailed in the form AOC-4. Other papers that can be
              transferred along with this form are used to maintain it.
              Filing of Annual Returns (MGT-7-ROC yearly return) Nidhi
              Company must submit Form MGT-7, a yearly return, to the
              MCA, or Ministry of Corporate Affairs (MCA).`,
            points: [],
          },
        ],
        content_img: '/imgs/assited/statutory-compliance.png',
        alt_img: 'TODAYFILINGS-Nidhi Company statutory Compliances',
      },
    ],
  };

  /* Img Content Component Data */
  const NidhiCompIcData = {
    id: 'dsc',
    mt_div: 'mt-n5',
    mt_img: '',
    header: "Documents needed for LLP's Nidhi Company Compliance",
    image: '/imgs/doc-req.png',
    alt_tag: "Documents needed for LLP's Nidhi Company Compliance",
    points: [
      {
        head: '',
        content: `The Nidhi Company's annual compliances aid in developing a
          precise understanding of the operation and performance of
          the business.`,
        icon: true,
      },
      {
        head: '',
        content: `Every company must register in accordance with the Companies
          Act of 2013 in order to submit the annual compliances.`,
        icon: true,
      },
      {
        head: '',
        content: `If preference shares are issued, they must be redeemed in
          accordance with the same conditions as the issue.`,
        icon: true,
      },
      {
        head: '',
        content: `Nidhi Business meets the definition of a public company; as
          a result, it aids in safeguarding the interests of its
          members and makes compliance with regulations a must for all
          Nidhi Companies.`,
        icon: true,
      },
    ],
  };

  return (
    <div>
      <Seo
        title='Nidhi Company Compliance'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />
      <Layout>
        <main id='main'>
          {/* <Hero sliders={NidhiCompSlider} /> */}
          <Package packageData={PackageCompData} />

          <About about_data={NidhiCompAboutData} />

          <Counter />

          <Service item={NidhiCompData} />

          <section id='private' className='private mt-3 main-content'>
            <div className='container mt-5'>
              <div className='section-header'>
                <h2>Essential Nidhi Company Compliance</h2>
                <div className='bar'></div>
              </div>
              <div className='sec-title mb-26'>
                <h2 className='title extra-bold'>
                  Nidhi Company's Annual Compliances
                </h2>
              </div>
              <p>
                The Nidhi Company's yearly compliance is cyclical, and because
                it is frequently reported annually, it must be filed on a
                regular basis after predetermined intervals. These annual
                compliances are meant to give readers a clear image of the
                company's current state of operations and Nidhi Company's
                performance over the previous year. The Nidhi Company must
                adhere to all compliance requirements outlined in the Nidhi
                Rules of 2014 and the Companies Act of 2013.
              </p>

              <table className='table table-hover table-bordered table-striped'>
                <thead className='bg-info text-white'>
                  <tr className='text-center'>
                    <td>
                      <strong>Compliance</strong>
                    </td>
                    <td>
                      <strong>Due Date</strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr className='text-center'>
                    <td>
                      <strong>Annual General Meeting(AGM)</strong>
                    </td>
                    <td>30th September of every year</td>
                  </tr>
                  <tr className='text-center'>
                    <td>
                      <strong>MGT-7</strong>
                    </td>
                    <td>Within 2 months or 60 days of AGM</td>
                  </tr>
                  <tr className='text-center'>
                    <td>
                      <strong>AOC-4</strong>
                    </td>
                    <td>Within a month or 30 days of AGM</td>
                  </tr>
                  <tr className='text-center'>
                    <td>
                      <strong>NDH 1</strong>
                    </td>
                    <td>Within 3 months or 90 days of the every Fiscal Year</td>
                  </tr>
                  <tr className='text-center'>
                    <td>
                      <strong>NDH 3</strong>
                    </td>
                    <td>Every 6 months or Half Yearly</td>
                  </tr>
                  <tr className='text-center'>
                    <td>
                      <strong>Income Tax Return</strong>
                    </td>
                    <td>30th September of every year</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
          <ImgContent item={NidhiCompIcData} />

          <GeneralTab HTabsData={HTabsData} />

          {/* <!-- ======= F.A.Q Section ======= --> */}
          <FAQAccord items={NidhiCompFAQ} />
        </main>
      </Layout>
    </div>
  );
}
